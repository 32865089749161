<template>
    <div>
        <v-layout row wrap text-xs-left class="mt-3" >
            <v-flex xs12 sm12 md4 class="white">
                <v-carousel :cycle="false" height="auto" style="height: 200px"> 
                    <v-carousel-item v-for="item in product_videos" :key="'video_'+item.id" style="height: 200px">
                        <video controls height="200">
                            <source :src="item.get_video" type="video/mp4">
                        </video>
                    </v-carousel-item> 
                    <v-carousel-item contain style="height: 200px; width: 300px" :src="product.get_image"></v-carousel-item> 
                    <v-carousel-item contain style="height: 200px; width: 300px" v-for="item in product_images" :key="'image_'+item.id" :src="item.get_image"></v-carousel-item> 
                </v-carousel> 
            </v-flex>
            <v-flex xs12 sm12 md8 >
                <v-card class="white pt-2 px-2"  style="min-height: 220px; border-radius: 10px" >
                    <h2 class="dark--text font-weight-bold"><u>{{product.name}}</u></h2>
                    <v-card-text class="pa-1" style="font-size: 12px; ">
                        <v-layout row wrap>
                            <v-flex xs12 sm6 text-xs-left class="pb-1">
                                <v-btn @click="addToShoppingBag()" color="teal" class="white--text pa-2 ma-1" small><i class="fas fa-shopping-basket mr-1"></i> Add To Shopping Bag</v-btn> 
                            </v-flex>
                            <v-flex xs12 sm6 text-xs-right class="pb-1">
                                <v-btn :to="{name: 'campaigns.new_campaign'}" color="primary" class="white--text pa-2 ma-1" small><i class="fas fa-piggy-bank mr-1"></i> Start Saving To Buy This</v-btn> 
                            </v-flex>
                            <v-flex xs6 text-xs-left>
                                <span class="dark--text font-weight-bold">Amount:</span><span> {{product.price}} FCFA</span>
                            </v-flex>
                            <v-flex xs6 text-xs-right>
                                <span class="dark--text font-weight-bold">Quantity:</span><span> {{product.quantity}}</span>
                            </v-flex>
                            <v-flex xs6 text-xs-left>
                                <!-- <span class="dark--text font-weight-bold ">Rating:</span><span> {{product.rating}}</span> -->
                                <span class="dark--text font-weight-bold">In-Stock:</span><span> {{product.quantity}}</span>
                            </v-flex>
                            <v-flex xs6 text-xs-right>
                                <span class="dark--text font-weight-bold ">Category:</span><span> {{product.category}}</span>
                            </v-flex>
                        </v-layout>
                        <v-divider></v-divider>
                        <p class="mt-2 text-blue subheading" style="line-height: 1.2"><b>Description:</b> 
                            <span v-html="product.description" class="text-blue" style="font-size: 12px"> </span>
                        </p>
                    </v-card-text>
                </v-card>
            </v-flex>
        </v-layout>
        <br>

        <v-card class="blue darken-4" >
            <v-card-text style="font-size: 12px; ">
                <span class="white--text text-xs-left subheading"><b>USER REVIEWS:</b> </span>
            </v-card-text>
        </v-card>
        
        <v-card class=" pa-1" >
            <v-card-text class="body-2" style="font-size: 12px; padding: 5px">
                <v-layout class="white pa-0" row v-for="item in itemComments" :key="item.product_review.id">
                    <v-flex class="pa-1" wrap xs12 style="font-size: 12px">
                        <b><u>{{ item.product_review.reviewer_name }}</u></b>
                        <p class="mb-0">{{ item.product_review.review }}</p>
                        <v-layout row>
                            <v-flex class="pa-1" xs4 style="font-size: 12px">
                                {{ item.product_review.no_likes }}
                                <b style="cursor: pointer;" text-xs-right @click="likeReview(item.product_review.id)"> like: <i class="fas fa-thumbs-up"></i></b>
                            </v-flex>
                            <v-flex class="pa-1" xs4 style="font-size: 12px">
                                {{ item.product_review.no_dislikes }}
                                <b style="cursor: pointer;" text-xs-left @click="dislikeReview(item.product_review.id)"> dislike: <i class="fas fa-thumbs-down"></i></b>
                            </v-flex>
                        </v-layout>
                        <hr>
                    </v-flex>
                </v-layout>
            </v-card-text>
        </v-card>
    </div>
</template>

<script>
    import axios from 'axios'

    export default {
        data() {
            return {
                product: {},
                product_images: {},
                product_videos: [],
                quantity: 0,
                name: "",

                itemComments: [],
            }
        },
        
        mounted(){
            // executes these after the page has been mounted
            this.getProductDetail()
            this.getProductReviews()
            this.$store.commit('setCloseURL', "/market_place/items/types/all")
            document.title = "PettyCash | Market Place: Product"
        },

        methods:{
            addToShoppingBag(){
                if(isNaN(this.quantity) || this.quantity < 1){
                    this.quantity = 1
                }
                const itemAdded = {
                    product: this.product,
                    quantity: this.quantity
                }
                this.$store.commit('addToShoppingBag', itemAdded)

                this.$store.commit('setSnackBarMessage', this.product.name + " added successfully to your shopping bag")
                this.$store.commit('setSnackBarColor', "teal darken-4")
                this.$store.commit('activateSnackBar', true)
                console.log(this.$store.state.shopping_bag)
            },

            async getProductDetail(){
                this.$store.commit('setIsLoading', true)
                
                const product_id = this.$route.params.key  // get campaign category from url and send request to server
                this.$store.commit('setSaveItem', product_id)
                await axios
                    .get('/api/v1/market_place/get/product/details/'+product_id+'/')
                    .then(response => {
                        console.log(response.data)
                        this.product = response.data["product_info"]  // get the data and fill into campaigns
                        this.product_images = response.data["product_images"]  // get the data and fill into campaigns
                        this.product_videos = response.data["product_videos"]  // get the data and fill into campaigns
                    })
                    .catch(error => {
                        if (error.response){
                            this.$store.commit('setSnackBarMessage', error.response.status + " " + error.response.statusText)
                            this.$store.commit('setSnackBarColor', "red darken-4")
                            this.$store.commit('activateSnackBar', true)
                        }else{
                            this.$store.commit('setSnackBarMessage', "An error occured, please check your internet connection and try again later.")
                            this.$store.commit('setSnackBarColor', "red darken-4")
                            this.$store.commit('activateSnackBar', true)
                        }
                    })
                this.$store.commit('setIsLoading', false)
            },

            async getProductReviews(){
                this.$store.commit('setIsLoading', true)
                
                const product_id = this.$route.params.key  // get campaign category from url and send request to server
                await axios
                    .get('/api/v1/market_place/get/product/reviews/'+product_id+'/')
                    .then(response => {
                        console.log(response.data)
                        this.itemComments = response.data  // get the data and fill into campaigns
                    })
                    .catch(error => {
                        if (error.response){
                            this.$store.commit('setSnackBarMessage', error.response.status + " " + error.response.statusText)
                            this.$store.commit('setSnackBarColor', "red darken-4")
                            this.$store.commit('activateSnackBar', true)
                        }else{
                            this.$store.commit('setSnackBarMessage', "An error occured, please check your internet connection and try again later.")
                            this.$store.commit('setSnackBarColor', "red darken-4")
                            this.$store.commit('activateSnackBar', true)
                        }
                    })
                this.$store.commit('setIsLoading', false)
            },

            async likeReview(review_id){
                this.$store.commit('setIsLoading', true)
                
                await axios
                    .get('/api/v1/market_place/like/product/reviews/'+review_id+'/')
                    .then(response => {
                        this.getProductReviews()
                        this.$store.commit('setSnackBarMessage', "Review like successful")
                        this.$store.commit('setSnackBarColor', "teal darken-4")
                        this.$store.commit('activateSnackBar', true)
                    })
                    .catch(error => {
                        if (error.response){
                            this.$store.commit('setSnackBarMessage', error.response.status + " " + error.response.statusText)
                            this.$store.commit('setSnackBarColor', "red darken-4")
                            this.$store.commit('activateSnackBar', true)
                        }else{
                            this.$store.commit('setSnackBarMessage', "An error occured, please check your internet connection and try again later.")
                            this.$store.commit('setSnackBarColor', "red darken-4")
                            this.$store.commit('activateSnackBar', true)
                        }
                    })
                this.$store.commit('setIsLoading', false)
            },

            async dislikeReview(review_id){
                this.$store.commit('setIsLoading', true)
                
                await axios
                    .get('/api/v1/market_place/dislike/product/reviews/'+review_id+'/')
                    .then(response => {
                        this.getProductReviews()
                        this.$store.commit('setSnackBarMessage', "Review dislike successful")
                        this.$store.commit('setSnackBarColor', "teal darken-4")
                        this.$store.commit('activateSnackBar', true)
                    })
                    .catch(error => {
                        if (error.response){
                            this.$store.commit('setSnackBarMessage', error.response.status + " " + error.response.statusText)
                            this.$store.commit('setSnackBarColor', "red darken-4")
                            this.$store.commit('activateSnackBar', true)
                        }else{
                            this.$store.commit('setSnackBarMessage', "An error occured, please check your internet connection and try again later.")
                            this.$store.commit('setSnackBarColor', "red darken-4")
                            this.$store.commit('activateSnackBar', true)
                        }
                    })
                this.$store.commit('setIsLoading', false)
            },
            
        },    
    }
</script>

<style scooped>
    p {
        margin-bottom: 5px;
    }
</style>
